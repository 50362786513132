<template>
  <div>
    <h3>{{ message }}</h3>
    <form @submit.prevent="onSubmit" enctype="multipart/form-data">
      <div>
        <label>请选择</label>
        <input   class="form-control" type="file" ref="file" @change="onSelect"  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" />
      </div>
      <div class="form-group">
          <label >样品代码:</label><input   class="form-control" v-model="samplecode" type="text" placeholder="需要准确"  />
      </div>
      <div class="form-group">
         <label >唯一文件名(类似:BGLQ05004F[海特模板]或C00001[组合模板]或EX00001[扩展模板]或P00001[个人模板,这些基本上不用上传了,放在自已电脑就好了]),不能大于10位,如果存在就会覆盖,如果没有就会新增,全网唯一性,很重要</label><input  placeholder="需要唯一"  class="form-control" v-model="stdcode" type="text"  />
      </div>
      <div class="form-group">
          <label >名称(想叫什么名字就什么名字)</label><input   class="form-control" v-model="name" type="text"  />
      </div>
      <input class="button" type="submit" />
    </form>
  </div>
</template>

<script>
import { baseUrl,bucketUrl,oss_access_key } from "@/config.js";
export default {
  name: "UploadTemplate",
  data() {
    return {
      file: "",
      message: "名字格式:CJL_Z00001_我要给你起名字.xlsx(三段命名法,下划线注意)",
      samplecode:"",
      stdcode:"",
      name:"",

    };
  },
  methods: {
    onSelect() {
      const file = this.$refs.file.files[0];
      this.file = file;
      const temp = file.name.split("_");
      this.samplecode=temp[0].toUpperCase();
      this.stdcode = temp[1];
      this.name = temp[2].split(".")[0];

    },
    async onSubmit() {
      if(!this.file){
        return ;
      }
      const key = `template/${this.stdcode}.xlsx`;
      try {
        const res = await fetch(`${baseUrl}/aliyun/templatetoken`, {
          method: "post",
          headers: {
            "Content-Type": "application/json;charset=utf-8",
            Authorization: "Bearer " + this.$store.state.auth.token,
          },
          body:JSON.stringify({samplecode:this.samplecode,stdcode:this.stdcode,name:this.name})
        });
        const json = await res.json();
        let formData = new FormData();
        formData.append("x:access_token", this.$store.state.auth.token);
        formData.append("OSSAccessKeyId",oss_access_key);
        formData.append("callback", json.callback);
        formData.append("policy", json.policy);
        formData.append("Signature", json.sign);
        formData.append("success_action_status", 201);
        formData.append("key", key);
        formData.append("x:var1", key);
        formData.append("file", this.file);
        let response = await fetch(`${bucketUrl}`,
          {
            method: "POST",
            body: formData,
          }
        );
        let result = await response.json();
        this.$refs.file.value = null;
        this.file = null;
        this.samplecode = "";
        this.stdcode = "";
        this.name = "";

        alert(`已上传到云端${(result.size/1024).toFixed(2)}KB`);
      } catch (e) {
        alert(e.message);
      }
    },
  },

};
</script>

<style scoped>
.button {
  background-color: #4CAF50; /* Green */
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin: 4px 2px;
  cursor: pointer;
}
.login{
  width: 30%;
  margin:auto;
}
h1{
  text-align: center;
  margin-top: 20%;
  margin-bottom: 20%;
}
</style>
